import { preBootstrap } from '@tripactions/core/util/pre-bootstrap.util';
import { TA_BOOTSTRAP } from '@tripactions/core/util/ta-bootstrap.util';
import { environment } from './environments/environment';

(async function main() {
  // Bootstrap application
  try {
    const [{ default: bootstrap }] = await Promise.all([
      (async () => {
        await TA_BOOTSTRAP.setup();
        await preBootstrap();
        return import(/* webpackPreload: true */ './bootstrap');
      })(),
      environment?.playerZero?.projectId
        ? import('@goplayerzero/sdk-web')
          .then(({ default: PlayerZero }) => {
            console.log('PlayerZero init %s', environment.playerZero.projectId);
            PlayerZero.init(environment.playerZero.projectId);
          })
        : Promise.resolve(),
    ]);
    bootstrap();
  } catch (error) {
    console.error(error);
  }
})();
