import { Environment } from './environment.zod';

export const environment: Environment = {
  baseUrl: '/app/user2',
  trustedRedirectBaseUrl: 'https://dev-app-staging.tripactions.com/',
  production: true,
  development: false,
  deployment: 'staging',
  pendoApiKey: process.env.PENDO_API_KEY,
  siftBeacon: '1d2541a86e',
  stripeKey: 'pk_test_Zn4OhPUCVpnsShTna3U9rR0M',
  rewardsStripeKey:
    'pk_test_51KML0GHJByLIO4FYWg78cQinMT4nPC3U8WQAJ2VwexJOUrODjXgejSfsxr0owZcoJplOdDHv7ODibAnoyq2C2NvO00dYrNh7Hv',
  amplitudeKey: process.env.AMPLITUDE_KEY,
  amplitudeStagingKey: process.env.AMPLITUDE_STAGING_KEY,
  amplitudeExperimentsKey: 'client-1qVisgsj8Zw2PFX7Ib1nXjcIoY6MzCdo',
  origins: [
    'https://dev-app-staging.trapactions.com',
    'https://staging-prime.tripactions.com',
    'https://dev-app-staging.navan.com',
    'https://staging-prime.navan.com',
  ],
  newRelic: {
    accountID: process.env.NR_ACCOUNT_ID,
    trustKey: process.env.NR_TRUST_KEY,
    agentID: process.env.NR_AGENT_ID,
    licenseKey: process.env.NR_LICENSE_KEY,
    appID: process.env.NR_APP_ID,
  },
  version: process.env.APP_VERSION,
  promotion25DollarsUuid: '1a4d0040-4e49-41a3-95b4-ad82f1defafb',
  api: {
    host: '',
    port: '',
    url: '/api/superAdmin',
    adminUrl: '/api/admin',
    userUrl: '/api/user',
    uaaUrl: '/api/uaa',
    travelxenUrl: '/api/travelxen/v1',
    dataService: '/api/travelxen/dataservice/v1',
    logUrl: '/api/log',
    v1Url: '/api/v1',
    delegateUrl: '/api/delegate',
  },
  auth0Env: 'staging',
  mlChatUrl: 'https://staging-prime.navan.com/app/ml-chat',
  validDomains: [
    /^(http:\/\/|https:\/\/)?staging-prime\.[^.]*\.netlify\.navan\.com\/?$/,
    /^(http:\/\/|https:\/\/)?(staging-prime\.navan\.com)\/?.*/,
  ],
  playerZero: {
    projectId: process.env.PLAYERZERO_PROJECT_ID,
  },
};
